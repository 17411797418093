import React from "react";

const PrivacyPolicyExtended: React.FC = () => {
  return (
    <>
      <p>
        <strong>Last updated: May 13, 2021</strong>
      </p>

      <p>
        This Privacy Policy describes Our policies and procedures on the
        collection, use and disclosure of Your information when You use the
        Service and tells You about Your privacy rights and how the law protects
        You.
      </p>

      <p>
        We use Your Personal data to provide and improve the Service. By using
        the Service, You agree to the collection and use of information in
        accordance with this Privacy Policy.
      </p>

      <h2>Interpretation and Definitions</h2>

      <h3>
        <strong>Interpretation</strong>
      </h3>

      <p>
        The words of which the initial letter is capitalized have meanings
        defined under the following conditions. The following definitions shall
        have the same meaning regardless of whether they appear in singular or
        in plural.
      </p>

      <h3>
        <strong>Definitions</strong>
      </h3>

      <p>For the purposes of this Privacy Policy:</p>

      <p>
        <strong>Account</strong> means a unique account created for You to
        access our Service or parts of our Service.
      </p>

      <p>
        <strong>Affiliate</strong> means an entity that controls, is controlled
        by or is under common control with a party, where “control” means
        ownership of 50% or more of the shares, equity interest or other
        securities entitled to vote for election of directors or other managing
        authority.
      </p>

      <p>
        <strong>Application</strong> means the software program provided by the
        Company downloaded by You on any electronic device, named Walksy. App
      </p>

      <p>
        <strong>Company</strong> (referred to as either “the Company”, “We”,
        “Us” or “Our” in this Agreement) refers to Art Walk Projects CIC, 1
        Kings Place, Edinburgh EH15 1DU .
      </p>

      <p>For the purpose of the GDPR, the Company is the Data Controller.</p>

      <p>
        <strong>Country</strong> refers to: United Kingdom
      </p>

      <p>
        <strong>Data Controller</strong>, for the purposes of the GDPR (General
        Data Protection Regulation), refers to the Company as the legal person
        which alone or jointly with others determines the purposes and means of
        the processing of Personal Data.
      </p>

      <p>
        <strong>Device</strong> means any device that can access the Service
        such as a computer, a cellphone or a digital tablet.
      </p>

      <p>
        <strong>Personal Data</strong> is any information that relates to an
        identified or identifiable individual.
      </p>

      <p>
        For the purposes for GDPR, Personal Data means any information relating
        to You such as a name, an identification number, location data, online
        identifier or to one or more factors specific to the physical,
        physiological, genetic, mental, economic, cultural or social identity.
      </p>

      <p>
        <strong>Service</strong> refers to the Application.
      </p>

      <p>
        <strong>Service Provider</strong> means any natural or legal person who
        processes the data on behalf of the Company. It refers to third-party
        companies or individuals employed by the Company to facilitate the
        Service, to provide the Service on behalf of the Company, to perform
        services related to the Service or to assist the Company in analyzing
        how the Service is used. For the purpose of the GDPR, Service Providers
        are considered Data Processors.
      </p>

      <p>
        <strong>Usage Data</strong> refers to data collected automatically,
        either generated by the use of the Service or from the Service
        infrastructure itself (for example, the duration of a page visit).
      </p>

      <p>
        <strong>You</strong> means the individual accessing or using the
        Service, or the company, or other legal entity on behalf of which such
        individual is accessing or using the Service, as applicable.
      </p>

      <p>
        Under GDPR (General Data Protection Regulation), You can be referred to
        as the Data Subject or as the User as you are the individual using the
        Service.
      </p>

      <h2>Collecting and Using Your Personal Data</h2>

      <h3>
        <strong>Types of Data Collected</strong>
      </h3>

      <h4>
        <strong>Personal Data</strong>
      </h4>

      <p>
        While using Our Service, We may ask You to provide Us with certain
        personally identifiable information that can be used to contact or
        identify You. Personally identifiable information may include, but is
        not limited to:
      </p>

      <p>Email address</p>

      <p>First name and last name</p>

      <p>Usage Data</p>

      <h4>
        <strong>Usage Data</strong>
      </h4>

      <p>Usage Data is collected automatically when using the Service.</p>

      <p>
        Usage Data may include information such as Your Device’s Internet
        Protocol address (e.g. IP address), browser type, browser version, the
        pages of our Service that You visit, the time and date of Your visit,
        the time spent on those pages, unique device identifiers and other
        diagnostic data.
      </p>

      <p>
        When You access the Service by or through a mobile device, We may
        collect certain information automatically, including, but not limited
        to, the type of mobile device You use, Your mobile device unique ID, the
        IP address of Your mobile device, Your mobile operating system, the type
        of mobile Internet browser You use, unique device identifiers and other
        diagnostic data.
      </p>

      <p>
        We may also collect information that Your browser sends whenever You
        visit our Service or when You access the Service by or through a mobile
        device.
      </p>

      <h4>
        <strong>Information Collected while Using the App</strong>
      </h4>

      <p>
        While using Our App, in order to provide features of Our App, We may
        collect, with Your prior permission:
      </p>

      <p>Information regarding your location</p>

      <p>
        Pictures and other information from your Device’s camera and photo
        library
      </p>

      <p>
        We use this information to provide features of Our Service, to improve
        and customize Our Service. The information may be uploaded to the
        Company’s servers and/or a Service Provider’s server or it may be simply
        stored on Your device.
      </p>

      <h3>
        <strong>Use of Your Personal Data</strong>
      </h3>

      <p>We may use Personal Data for the following purposes:</p>

      <p>
        <strong>To provide and maintain our Service</strong>, including to
        monitor the usage of our Service.
      </p>

      <p>
        <strong>To manage Your Account:</strong> to manage Your registration as
        a user of the Service. The Personal Data You provide can give You access
        to different functionalities of the Service that are available to You as
        a registered user.
      </p>

      <p>
        <strong>To provide You</strong> with news, special offers and general
        information about other goods, services and events which we offer that
        are similar to those that you have already purchased or enquired about
        unless You have opted not to receive such information.
      </p>

      <p>
        <strong>To manage Your requests:</strong> To attend and manage Your
        requests to Us.
      </p>

      <p>
        We are committed to safeguarding the privacy of all our App users and
        will not sell, distribute or lease your personal data to any third
        party.
      </p>

      <h3>
        <strong>Retention of Your Personal Data</strong>
      </h3>

      <p>
        The Company will retain Your Personal Data only for as long as is
        necessary for the purposes set out in this Privacy Policy. We will
        retain and use Your Personal Data to the extent necessary to comply with
        our legal obligations (for example, if we are required to retain your
        data to comply with applicable laws), resolve disputes, and enforce our
        legal agreements and policies.
      </p>

      <p>
        The Company will also retain Usage Data for internal analysis purposes.
        Usage Data is generally retained for a shorter period of time, except
        when this data is used to strengthen the security or to improve the
        functionality of Our Service, or We are legally obligated to retain this
        data for longer time periods.
      </p>

      <h3>
        <strong>Transfer of Your Personal Data</strong>
      </h3>

      <p>
        Your information, including Personal Data, is processed at the Company’s
        operating offices and in any other places where the parties involved in
        the processing are located. It means that this information may be
        transferred to — and maintained on — computers located outside of Your
        state, province, country or other governmental jurisdiction where the
        data protection laws may differ than those from Your jurisdiction.
      </p>

      <p>
        Your consent to this Privacy Policy followed by Your submission of such
        information represents Your agreement to that transfer.
      </p>

      <p>
        The Company will take all steps reasonably necessary to ensure that Your
        data is treated securely and in accordance with this Privacy Policy and
        no transfer of Your Personal Data will take place to an organization or
        a country unless there are adequate controls in place including the
        security of Your data and other personal information.
      </p>

      <h2>
        <strong>GDPR Privacy</strong>
      </h2>

      <h3>
        <strong>Legal Basis for Processing Personal Data under GDPR</strong>
      </h3>

      <p>We may process Personal Data under the following conditions:</p>

      <ul>
        <li>
          <strong>Consent:</strong> You have given Your consent for processing
          Personal Data for one or more specific purposes.
        </li>
        <li>
          <strong>Performance of a contract:</strong> Provision of Personal Data
          is necessary for the performance of an agreement with You and/or for
          any pre-contractual obligations thereof.
        </li>
        <li>
          <strong>Legal obligations:</strong> Processing Personal Data is
          necessary for compliance with a legal obligation to which the Company
          is subject.
        </li>
        <li>
          <strong>Vital interests:</strong> Processing Personal Data is
          necessary in order to protect Your vital interests or of another
          natural person.
        </li>
        <li>
          <strong>Public interests:</strong> Processing Personal Data is related
          to a task that is carried out in the public interest or in the
          exercise of official authority vested in the Company.
        </li>
        <li>
          <strong>Legitimate interests:</strong> Processing Personal Data is
          necessary for the purposes of the legitimate interests pursued by the
          Company.
        </li>
      </ul>

      <p>
        In any case, the Company will gladly help to clarify the specific legal
        basis that applies to the processing, and in particular whether the
        provision of Personal Data is a statutory or contractual requirement, or
        a requirement necessary to enter into a contract.
      </p>

      <h3>
        <strong>Your Rights under the GDPR</strong>
      </h3>

      <p>
        The Company undertakes to respect the confidentiality of Your Personal
        Data and to guarantee You can exercise Your rights.
      </p>

      <p>
        You have the right under this Privacy Policy, and by law if You are
        within the EU, to:
      </p>

      <ul>
        <li>
          <strong>Request access to Your Personal Data.</strong> The right to
          access, update or delete the information We have on You. Whenever made
          possible, you can access, update or request deletion of Your Personal
          Data directly within Your account settings section. If you are unable
          to perform these actions yourself, please contact Us to assist You.
          This also enables You to receive a copy of the Personal Data We hold
          about You.
        </li>
        <li>
          <strong>
            Request correction of the Personal Data that We hold about You.
          </strong>{" "}
          You have the right to have any incomplete or inaccurate information We
          hold about You corrected.
        </li>
        <li>
          <strong>Object to processing of Your Personal Data.</strong> This
          right exists where We are relying on a legitimate interest as the
          legal basis for Our processing and there is something about Your
          particular situation, which makes You want to object to our processing
          of Your Personal Data on this ground. You also have the right to
          object where We are processing Your Personal Data for direct marketing
          purposes.
        </li>
        <li>
          <strong>Request erasure of Your Personal Data.</strong> You have the
          right to ask Us to delete or remove Personal Data when there is no
          good reason for Us to continue processing it.
        </li>
        <li>
          <strong>Request the transfer of Your Personal Data.</strong> We will
          provide to You, or to a third-party You have chosen, Your Personal
          Data in a structured, commonly used, machine-readable format. Please
          note that this right only applies to automated information which You
          initially provided consent for Us to use or where We used the
          information to perform a contract with You.
        </li>
        <li>
          <strong>Withdraw Your consent.</strong> You have the right to withdraw
          Your consent on using your Personal Data. If You withdraw Your
          consent, We may not be able to provide You with access to certain
          specific functionalities of the Service.
        </li>
      </ul>

      <h3>
        <strong>Exercising of Your GDPR Data Protection Rights</strong>
      </h3>

      <p>
        You may exercise Your rights of access, rectification, cancellation and
        opposition by contacting Us. Please note that we may ask You to verify
        Your identity before responding to such requests. If You make a request,
        We will try our best to respond to You as soon as possible.
      </p>

      <p>
        You have the right to complain to a Data Protection Authority about Our
        collection and use of Your Personal Data. For more information, if You
        are in the European Economic Area (EEA), please contact Your local data
        protection authority in the EEA.
      </p>

      <h2>
        <strong>Children’s Privacy</strong>
      </h2>

      <p>
        Our Service does not address anyone under the age of 13. We do not
        knowingly collect personally identifiable information from anyone under
        the age of 13. If You are a parent or guardian and You are aware that
        Your child has provided Us with Personal Data, please contact Us. If We
        become aware that We have collected Personal Data from anyone under the
        age of 13 without verification of parental consent, We take steps to
        remove that information from Our servers.
      </p>

      <p>
        If We need to rely on consent as a legal basis for processing Your
        information and Your country requires consent from a parent, We may
        require Your parent’s consent before We collect and use that
        information.
      </p>

      <h2>
        <strong>Links to Other Websites</strong>
      </h2>

      <p>
        Our Service may contain links to other websites that are not operated by
        Us. If You click on a third party link, You will be directed to that
        third party’s site. We strongly advise You to review the Privacy Policy
        of every site You visit.
      </p>

      <p>
        We have no control over and assume no responsibility for the content,
        privacy policies or practices of any third party sites or services.
      </p>

      <h2>
        <strong>Changes to this Privacy Policy</strong>
      </h2>

      <p>
        We may update Our Privacy Policy from time to time. We will notify You
        of any changes by posting the new Privacy Policy on this page.
      </p>

      <p>
        We will let You know via email and/or a prominent notice on Our Service,
        prior to the change becoming effective and update the “Last updated”
        date at the top of this Privacy Policy.
      </p>

      <p>
        You are advised to review this Privacy Policy periodically for any
        changes. Changes to this Privacy Policy are effective when they are
        posted on this page.
      </p>

      <h2>
        <strong>Contact Us</strong>
      </h2>

      <p>
        If you have any questions about this Privacy Policy, You can contact us:
      </p>

      <ul>
        <li>
          By email:{" "}
          <a href="mailto:walksy@artwalkporty.co.uk">
            walksy@artwalkporty.co.uk
          </a>
        </li>
      </ul>
    </>
  );
};

export default PrivacyPolicyExtended;
